import * as tags from './tags';

[
	["1 Samuel","1\\s{0,2}Sam?(?:uel)?",true],
	["2 Samuel","2\\s{0,2}Sam?(?:uel)?",true],
	["1 Kings","1\\s{0,2}Ki(?:ngs?)?",true],
	["2 Kings","2\\s{0,2}Ki(?:ngs?)?",true],
	["1 Chronicles","1\\s{0,2}Ch(?:ron(?:icles?)?)?",true],
	["2 Chronicles","2\\s{0,2}Ch(?:ron(?:icles?)?)?",true],
	["1 Corinthians","1\\s{0,2}Co(?:r|rinthians?)?",true],
	["2 Corinthians","2\\s{0,2}Co(?:r|rinthians?)?",true],
	["1 Thessalonians","1\\s{0,2}Th(?:essalonians?)?",true],
	["2 Thessalonians","2\\s{0,2}Th(?:essalonians?)?",true],
	["1 Timothy","1\\s{0,2}Tim(?:othy)?",true],
	["2 Timothy","2\\s{0,2}Tim(?:othy)?",true],
	["1 Peter","1\\s{0,2}Peter",true],
	["2 Peter","2\\s{0,2}Peter",true],
	["1 John","1\\s{0,2}John",true],
	["2 John","2\\s{0,2}John",true],
	["3 John","3\\s{0,2}John",false],
	["Genesis","Ge|Gen|Gene|Genesis",true],
	["Exodus","Ex|Exo|Exod|Exodus",true],
	["Leviticus","Le|Lev|Levi|Leviticus",true],
	["Numbers","Nu|Num|Numb|Numbers",true],
	["Deuteronomy","De|Deu|Deut|Deuteronomy",true],
	["Joshua","Jos|Josh|Joshua",true],
	["Judges","Judg|Judges",true],
	["Ruth","Ru|Rut|Ruth",true],
	["Ezra","Ez|Ezr|Ezra",true],
	["Nehemiah","Neh|Nehe|Nehemiah",true],
	["Esther","Est|Esth|Esther",true],
	["Job","Job",true],
	["Psalms","Ps|Psa|Psalms?",true],
	["Proverbs","Pr|Pro|Prov|Proverbs?",true],
	["Ecclesiastes","Eccl|Ecclesiastes?",true],
	["Song of Solomon","So|Son|Songs?|Song of Solomon",true],
	["Isaiah","Isa|Isaiah",true], // "Is" is too common an English word
	["Jeremiah","Jer|Jere|Jeremiah",true],
	["Lamentations","Lam|Lame|Lamentations",true],
	["Ezekiel","Ez|Eze|Ezek|Ezekiel",true],
	["Daniel","Da|Dan|Daniel",true],
	["Hosea","Ho|Hos|Hosea",true],
	["Joel","Joel",true],
	["Amos","Amos",true],
	["Obadiah","Ob|Oba|Obad|Obadiah",false],
	["Jonah","Jon|Jonah",true],
	["Micah","Mi|Mic|Micah",true],
	["Nahum","Na|Nah|Nahum",true],
	["Habakkuk","Hab|Habakkuk",true],
	["Zephaniah","Zeph|Zephaniah",true],
	["Haggai","Hag|Haggai",true],
	["Zechariah","Zech|Zechariah|Zecheriah",true], // catch a common misspelling
	["Malachi","Mal|Mala|Malachi",true],
	["Matthew","Mat|Matt|Matthew",true],
	["Mark","Mar|Mark",true],
	["Luke","Lu|Luk|Luke",true],
	["John","Jo|Joh|John",true],
	["Acts","Ac|Act|Acts",true],
	["Romans","Ro|Rom|Romans",true],
	["Galatians","Ga|Gal|Galatians?",true],
	["Ephesians","Ep|Eph|Ephesians?",true],
	["Philippians","Philippians?",true],
	["Colossians","Col|Colossians?",true],
	["Titus","Tit|Titus",true],
	["Philemon","Phile(?:mon)?",false],
	["Hebrews","He\\.|Heb|Hebrews?",true],
	["James","Ja|Jam|James?",true],
	["Jude","Jude",false],
	["Revelation","Re|Rev|Reve|Revelation",true]
].forEach(function(data){
	var typename = "bible-"+data[0].toLowerCase().replace(/ /g,'');
	var formatname = data[0];
	var regexname = data[1];
	var haschapters = data[2];
	var regex, matchfunc, formatfunc, linkfunc=null;
	if (haschapters) {
		regex = RegExp("\\b((?:"+regexname+")\\s{0,3})(\\d{1,}(?:\:(\\d{1,})(?:(-?,?)(?:\\d{0,3}:)?(\\d{0,3}))*)?)","i")
		matchfunc = function(matchobj){
			var tags = [];
			var text = matchobj[0].substring(matchobj[1].length);
			let textoffset = matchobj[1].length;
			var m = /(\d{1,})(?:\:(\d{1,}))?/.exec(text);
			//console.log(m);
			if (m == null) {
				tags.push({text:formatname,frompage:0,topage:99999});
			}
			else {
				let from=0,to=99999,lastnum=0,origtextstart=0,origtextlen=textoffset+m.index+m[0].length;
				if (m[2]) {
					from=parseInt(m[1])*1000+parseInt(m[2]);
					to=from;
				}
				else {
					from=parseInt(m[1])*1000;
					to=parseInt(m[1])*1000+999;
				}
				lastnum=from;
				text = text.substring(m.index+m[0].length);
				textoffset += m.index+m[0].length;
				while (text.length>0) {
					m = /(-|,)(?:(\d{0,3}:))?(\d{1,3})/.exec(text);
					if (m==null) { break; }
					//console.log(m);
					if (m[1]=="-") {
						// range
						//console.log("range");
						if (from!=0) { //make sure there was a start to the range!
							if (m[2]) {
								to=parseInt(m[2])*1000+parseInt(m[3]);
							}
							else {
								// remove the verse from from, just keep the chapter
								to=(from-(from%1000))+parseInt(m[3]);
							}
							origtextlen += m[0].length;
							// validate that to is greater than or equal to from
							to = Math.max(from,to);
							lastnum = to;
							tags.push({text:formatname,frompage:from,topage:to,origtextstart:origtextstart,origtextlen:origtextlen});
							from=0,to=99999;
						}
					}
					else {
						//console.log("list");
						if (from!=0) {
							tags.push({text:formatname,frompage:from,topage:to,origtextstart:origtextstart,origtextlen:origtextlen});
						}
						// the +-1 is to remove the comma
						origtextstart=textoffset+m.index+1;
						origtextlen=m[0].length-1;
						if (m[2]) {
							from=parseInt(m[2])*1000+parseInt(m[3]);
						}
						else {
							from=(lastnum-(lastnum%1000))+parseInt(m[3]);
						}
						to=from;
						lastnum = from;
					}
					text = text.substring(m.index+m[0].length);
					textoffset += m.index+m[0].length;
				}
				if (from!=0) {
					tags.push({text:formatname,frompage:from,topage:to,origtextstart:origtextstart,origtextlen:origtextlen});
				}
			}
			return tags;
		};
		formatfunc = function(tag){
			var s = formatname;
			var fchap=null;
			if (tag.frompage!=0) {
				fchap = Math.floor(tag.frompage/1000);
				var fverse = tag.frompage%1000;
				s += (" "+fchap);
				if (fverse!=0) { s += (":"+fverse); }
			}
			if (tag.topage!=99999 && tag.topage!=(fchap*1000+999) && tag.topage!=tag.frompage) {
				var chap = Math.floor(tag.topage/1000);
				var verse = tag.topage%1000;
				if (fchap==chap) {
					s += ("-"+verse);
				}
				else {
					s += ("-"+chap);
					if (verse!=0) { s += (":"+verse); }
				}
			}
			return s
		};
	}
	else {
		// no chapters
		regex = RegExp("((?:"+regexname+")\\s{0,3})(\\d{1,})(?:(-?,?)(\\d{1,3}))*","i")
		matchfunc = function(matchobj){
			var tags = [];
			var text = matchobj[0].substring(matchobj[1].length);
			let textoffset = matchobj[1].length;
			var m = /(\d{1,})/.exec(text);
			//console.log(m);
			if (m == null) {
				tags.push({text:formatname,frompage:0,topage:999});
			}
			else {
				let from=0,to=999,lastnum=0,origtextstart=0,origtextlen=textoffset+m.index+m[0].length;
				from=parseInt(m[1]);
				to=from;
				text = text.substring(m.index+m[0].length);
				textoffset += m.index+m[0].length;
				while (text.length>0) {
					m = /(-|,)(\d{1,3})/.exec(text);
					if (m==null) { break; }
					//console.log(m);
					if (m[1]=="-") {
						// range
						//console.log("range");
						if (from!=0) { //make sure there was a start to the range!
							to=parseInt(m[2]);
							// validate that to is greater than or equal to from
							to = Math.max(from,to);
							origtextlen += m[0].length;
							tags.push({text:formatname,frompage:from,topage:to,origtextstart:origtextstart,origtextlen:origtextlen});
							from=0,to=999;
						}
					}
					else {
						//console.log("list");
						if (from!=0) {
							tags.push({text:formatname,frompage:from,topage:to,origtextstart:origtextstart,origtextlen:origtextlen});
						}
						// the +-1 is to remove the comma
						origtextstart=textoffset+m.index+1;
						origtextlen=m[0].length-1;
						from=parseInt(m[2]);
					}
					text = text.substring(m.index+m[0].length);
					textoffset += m.index+m[0].length;
				}
				if (from!=0) {
					tags.push({text:formatname,frompage:from,topage:to,origtextstart:origtextstart,origtextlen:origtextlen});
				}
			}
			return tags;
		};
		formatfunc = function(tag){
			var s = formatname;
			if (tag.frompage!=0) {
				s += (" "+tag.frompage);
			}
			if (tag.topage!=99999 && tag.topage!=tag.frompage) {
				s += ("-"+tag.topage);
			}
			return s
		};
	}
	linkfunc = function(tag) {
		return "https://www.biblegateway.com/passage/?search="+encodeURIComponent(formatfunc(tag));
	}
	tags.add({
		name:typename,
		re:regex,
		match:matchfunc,
		link:linkfunc,
		format:formatfunc
	});
});
